import React from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card";
import Button from "components/CustomButtons/Button";
import {CardMedia, withStyles} from "@material-ui/core";
import Pills from "./Pills.jsx";
import abi from "abis/tournamentAbi";
import humanStandardTokenAbi from "abis/humanStandardToken";
import {base, contract_address} from "constants.js";
import {apolloClient, prepUserForContract, sleep, initWeb3} from "utils";
import {
    ADD_PARTICIPANT,
    GET_TOURNAMENT,
    PICK_WINNER,
    REMOVE_PARTICIPANT,
    ROUND_UPDATE,
    START_TOURNAMENT
} from "./graphs";
import "../Components/App.css"; // TODO: Make sure it's safe to delete this.
import componentsStyle from "assets/jss/material-kit-react/views/components.jsx";
import PrizesModal from "./modals/PrizesModal";
import ContestantsModal from "./modals/ContestantsModal";
import ContributeModal from "./modals/ContributeModal";
import ParticipateModal from "./modals/ParticipateModal";
import SelectWinnerModal from "./modals/SelectWinnerModal";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import BattleRoyale from "./BattleRoyale";
import WhitelistContributorModal from "./modals/WhitelistContributorModal";
import CardBody from "../../components/Card/CardBody";
import {isUserSignedIn} from "../../utils";

const $ = (window.jQuery = require("jquery"));
require("../../../node_modules/jquery-bracket/dist/jquery.bracket.min.js");
require("../../../node_modules/jquery-bracket/dist/jquery.bracket.min.css");

class Tournament extends React.Component {
    state = {
        id: null,
        tournament: {},
        maxPlayers: 0,
        participants: [],
        matches: [],
        matchesL: [],
        user: {},
        owner: {},
        web3: null,
        assistInstance: null,
        contract: null,
        decoratedContract: null,
        tokenName: "ETH",
        tokenVersion: 0,
        contribution: 0,
        tokenUsdPrice: 0,
        prize: 0,
        deadline: 0,
        title: "",
        contributorPublicAddress: "",
        prizesModal: false,
        contestantsModal: false,
        contributeModal: false,
        participateModal: false,
        selectWinnerModal: false,
        whitelistContributorModal: false,
        match: {},
        isUserSignedIn: false,
        coverImage: ""
    };

    handleModalClickOpen = modal => {
        var x = [];
        x[modal] = true;
        this.setState(x);
    };

    handleModalClose = modal => {
        var x = [];
        x[modal] = false;
        this.setState(x);
    };

    handleMatchClick = match => {
        if (localStorage.getItem("userId") === this.state.tournament.owner.id) {
            this.setState({match: match});
            this.handleModalClickOpen("selectWinnerModal");
        }
    };

    handleFiles = e => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        axios
            .post(`${base}/api/tournament/${this.state.id}/coverImage`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then(response => {
                sleep(3000).then(() => {
                    this.setState({coverImage: response.data.fileUrl});
                });
            });
    };

    handleFilesThumbnail = e => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        axios
            .post(`${base}/api/tournament/${this.state.id}/thumbnail`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then(response => {
                // TODO: Add snackbar here
                sleep(3000).then(() => {
                    this.setState({thumbnail: response.data.fileUrl});
                });
            });
    };

    componentDidMount = async () => {
        this.setState({id: this.props.match.params.id});
        this.getTournament(this.props.match.params.id);
        let bncAssistConfig = {
            messages: {
                txPending: data => {
                    if (data.contract.methodName === "approve") {
                        return "Approving funds to be contributed.";
                    } else {
                        return "Transaction Pending";
                    }
                },
                txConfirmed: data => {
                    if (data.contract.methodName === "approve") {
                        this.setState(
                            {
                                decoratedContract: this.state.assistInstance.Contract(
                                    this.state.web3.eth.contract(abi).at(contract_address)
                                )
                            },
                            () => {
                                this.state.decoratedContract.contribute(
                                    this.state.tournament.tournamentId,
                                    this.state.contribution,
                                    {
                                        from: this.state.user.publicAddress
                                    },
                                    (err) => {
                                        if (!err) {
                                            window.location.reload();
                                        }
                                    }
                                );
                            }
                        );
                        return `${this.state.web3.fromWei(this.state.contribution, "ether")} ${
                            this.state.tokenName
                        } approved.`;
                    } else if (data.contract.methodName === "contribute") {
                        sleep(5000).then(() => {
                            window.location.reload();
                            return "Contribution successful.";
                        });
                    } else if (data.contract.methodName === "payoutWinners") {
                        sleep(10000).then(() => {
                            this.getTournament(this.props.match.params.id);
                            return "Winners successfully paid.";
                        });
                    } else {
                        return "Transaction Confirmed";
                    }
                }
            },
            style: {
                darkMode: true
            }
        };
        const web3Init = await initWeb3(bncAssistConfig);
        this.setState({isUserSignedIn: await isUserSignedIn()});
        this.setState({web3: web3Init.web3, assistInstance: web3Init.assistInstance});
    };

    getTournament = id => {
        apolloClient
            .query({
                query: GET_TOURNAMENT(id)
            })
            .then(response => {
                if (response.loading) return "Loading...";
                if (response.error) return `Error!`;
                const data = response.data;
                this.setState({
                    ...this.state.tournament,
                    tournament: data.tournament
                });
                this.setState({tokenName: data.tournament.token.symbol});
                this.setState({tokenVersion: data.tournament.token.tokenVersion});
                this.setState({coverImage: data.tournament.coverImage});
                this.setState({owner: data.tournament.owner});
                this.setState({maxPlayers: data.tournament.maxPlayers});
                this.setState({title: data.tournament.name});
                this.setState({
                    ...this.state.participants,
                    participants: data.tournament.participants
                });
                this.setState({matches: data.tournament.matches});
                this.setState({matchesL: data.tournament.matchesL});
                this.setState({tokenUsdPrice: data.tournament.token.usdPrice});
                this.setState({prize: data.tournament.prize});
                this.setState({deadline: data.tournament.deadline});
                this.setState({tournamentType: data.tournament.tournamentType});
                return null;
            });
    };

    handleParticipation = teamId => {
        apolloClient.mutate({
            variables: {tournamentId: this.state.tournament.id, teamId},
            mutation: ADD_PARTICIPANT
        }).then(response => {
            if (response.loading) return "Loading...";
            if (response.error) return console.error("Error when enrolling team.");

            const tournament = response.data.addParticipant;
            this.setState({
                ...this.state.tournament,
                tournament: tournament
            });
            this.setState({
                ...this.state.participants,
                participants: tournament.participants
            });
            this.setState({
                ...this.state.matches,
                matches: tournament.matches
            });
            return null;
        });
    };

    removeParticipant = teamId => {
        apolloClient.mutate({
            mutation: REMOVE_PARTICIPANT,
            variables: {tournamentId: this.state.tournament.id, teamId}
        }).then(res => {
            if (res.loading) return "Loading...";
            if (res.error) return console.error("Error when removing team.");

            const tournament = res.data.removeParticipant;
            this.setState({
                ...this.state.tournament,
                tournament: tournament
            });
            this.setState({
                ...this.state.participants,
                participants: tournament.participants
            });
            this.setState({
                ...this.state.matches,
                matches: tournament.matches
            });
            return null;
        });
    };

    handleStartTournament = () => {
        apolloClient.mutate({
            variables: {tournamentId: this.state.tournament.id},
            mutation: START_TOURNAMENT
        }).then(response => {
            this.setState({tournament: response.data.startTournament});
            this.setState({
                ...this.state.matches,
                matches: response.data.startTournament.matches
            });
        });
    };

    handlePointUpdate = event => {
        const round = event.target.name.split(",")[0];
        const userId = event.target.name.split(",")[1];
        const tournament = this.state.tournament;
        tournament.rounds[round][userId] = parseInt(event.target.value);
        this.setState({tournament: tournament});
        console.log(tournament);
    };

    endRound = roundNumber => {
        apolloClient.mutate({
            variables: {
                tournamentId: this.state.tournament.id,
                roundNumber,
                standings: this.state.tournament.rounds[roundNumber]
            },
            mutation: ROUND_UPDATE
        }).then(response => {
            this.setState({tournament: response.data.roundUpdate});
        });
    };

    handleWinner = (matchId, num) => {
        let finalMatch;
        if (this.state.tournament.bracketType === "SINGLE_ELIMINATION") {
            finalMatch = this.state.matches[this.state.matches.length - 1];
        } else if (this.state.tournament.bracketType === "DOUBLE_ELIMINATION") {
            finalMatch = this.state.matchesL[this.state.matchesL.length - 1];
        }
        if (finalMatch.id === matchId) {
            this.handlePayment(num);
        } else {
            apolloClient
                .mutate({
                    variables: {pos: Number(num), matchId: matchId},
                    mutation: PICK_WINNER
                })
                .then(response => {
                    this.setState({tournament: response.data.matchWinner});
                    this.setState({...this.state.matches, matches: response.data.matchWinner.matches});
                    this.setState({...this.state.matchesL, matchesL: response.data.matchWinner.matchesL});
                });
        }
    };

    handleContributorWhitelist = () => {
        prepUserForContract(this.state.assistInstance, this.props.history)
            .then(responseData => {
                this.setState(
                    {
                        decoratedContract: this.state.assistInstance.Contract(
                            this.state.web3.eth.contract(abi).at(contract_address)
                        )
                    }, () => {
                        console.log({addr: this.state.contributorPublicAddress});
                        this.state.decoratedContract.whitelistContributor(this.state.tournament.tournamentId, this.state.contributorPublicAddress, {from: this.state.user.publicAddress}, (err) => {
                            if (!err) console.log("Whitelisting successful")
                        });
                    });
            });
    };

    handleSimple = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    handlePayment = winner => {
        prepUserForContract(this.state.assistInstance, this.props.history).then(
            responseData => {
                this.setState(
                    {
                        decoratedContract: this.state.assistInstance.Contract(
                            this.state.web3.eth.contract(abi).at(contract_address)
                        )
                    },
                    () => {
                        // This is for single elimination only
                        let winners = [];
                        let finalMatch = this.state.matches[Object.keys(this.state.matches).length - 1];

                        if (winner === 1) {
                            finalMatch.team1.members.forEach(member => winners.push(member.publicAddress));
                            finalMatch.team2.members.forEach(member => winners.push(member.publicAddress));
                        } else {
                            finalMatch.team2.members.forEach(member => winners.push(member.publicAddress));
                            finalMatch.team1.members.forEach(member => winners.push(member.publicAddress));
                        }
                        if (this.state.tournament.matchesL[0].winner) {
                            if (this.state.tournament.matchesL[0].winner.id === this.state.tournament.matchesL[0].team1.id) {
                                this.state.tournament.matchesL[0].team1.members.forEach(member => winners.push(member.publicAddress));
                                this.state.tournament.matchesL[0].team2.members.forEach(member => winners.push(member.publicAddress));
                            } else {
                                this.state.tournament.matchesL[0].team2.members.forEach(member => winners.push(member.publicAddress));
                                this.state.tournament.matchesL[0].team1.members.forEach(member => winners.push(member.publicAddress));
                            }
                        } else {
                            window.alert("3rd place match has not been determined");
                        }
                        for (let i = 0; i < (this.state.tournament.maxTeams - 4) * this.state.tournament.teamSize; i++) {
                            winners.push(this.state.tournament.owner.publicAddress);
                        }
                        console.table(winners);

                        this.state.decoratedContract.payoutWinners(
                            this.state.tournament.tournamentId,
                            winners,
                            {from: this.state.tournament.owner.publicAddress},
                            (err) => {
                                if (!err) {
                                    console.log("Contract successful");
                                }
                            }
                        );
                    }
                );
            }
        );
    };

    handlePaymentBR = () => {
        const winners = this.state.tournament.winners;
        for (let i = 0; i < this.state.tournament.maxPlayers; i++) {
            if (winners[i] === undefined) {
                winners[i] = this.state.user.publicAddress;
            }
        }

        this.setState(
            {
                decoratedContract: this.state.assistInstance.Contract(
                    this.state.web3.eth.contract(abi).at(contract_address)
                )
            },
            () => {
                this.state.decoratedContract.payoutWinners(
                    this.state.tournament.tournamentId,
                    this.state.tournament.winners,
                    {from: this.state.user.publicAddress},
                    (err) => {
                        if (!err) {
                            console.log("Contract successful");
                        }
                    }
                );
            }
        );
    };

    handleFunding = () => {

        if (this.state.tournamentType === "BUY_IN") {
            this.setState({contribution: this.state.tournament.buyInFee});
        }

        prepUserForContract(this.state.assistInstance, this.props.history).then(
            responseData => {
                this.setState({...this.state.user, user: responseData});
                if (this.state.tokenVersion === 20) {

                    // Fix up to BigNumber
                    let decimals = this.state.web3.toBigNumber(18); // TODO: Get this from token object
                    let amount = this.state.web3.toBigNumber(this.state.contribution);
                    let value = amount.times(this.state.web3.toBigNumber(10).pow(decimals));
                    this.setState({contribution: value});

                    this.setState(
                        {
                            decoratedContract: this.state.assistInstance.Contract(
                                this.state.web3.eth
                                    .contract(humanStandardTokenAbi)
                                    .at(this.state.tournament.token.address)
                            )
                        },
                        () => {
                            this.state.decoratedContract.approve(
                                contract_address,
                                this.state.contribution,
                                {from: this.state.user.publicAddress}
                            );
                        }
                    );
                } else if (this.state.tokenVersion === 0) {
                    this.setState(
                        {
                            decoratedContract: this.state.assistInstance.Contract(
                                this.state.web3.eth.contract(abi).at(contract_address)
                            )
                        },
                        () => {
                            this.state.decoratedContract.contribute.sendTransaction(
                                this.state.tournament.tournamentId,
                                this.state.web3.toWei(this.state.contribution, "ether"),
                                {
                                    from: this.state.user.publicAddress,
                                    value: this.state.web3.toWei(this.state.contribution, "ether"),
                                    gas: 200000
                                }
                            );
                        }
                    );
                } else {
                    window.alert("Working on it!");
                }
            });
    };

    render() {
        const {classes} = this.props;

        if (this.state.matches.length > 0) {
            const teams = [];
            this.state.matches.forEach((match, i) => {
                if (i < this.state.tournament.maxTeams / 2) {
                    teams.push([match.team1.name, match.team2.name]);
                }
            });

            if (this.state.tournament.bracketType === "SINGLE_ELIMINATION") {
                let matchCount = this.state.matches.length;
                let count = 0;
                let rounds = [];
                let round = [];
                this.state.matches.forEach((match) => {
                    if (count > matchCount / 2) {
                        rounds.push(round);
                        matchCount = matchCount - count;
                        count = 0;
                        round = [];
                    }
                    if (match.winner) {
                        if (match.winner.id === match.team1.id) {
                            round.push([1, 0, match]);
                        } else {
                            round.push([0, 1, match]);
                        }
                    } else {
                        round.push([null, null, match]);
                    }
                    count++;
                });

                // Add in final match
                let final = [];
                const finalMatch = this.state.matches[this.state.matches.length - 1];
                if (finalMatch.winner) {
                    if (finalMatch.winner.id === finalMatch.team1.id) {
                        final.push([1, 0, finalMatch]);
                    } else {
                        final.push([0, 1, finalMatch]);
                    }
                } else {
                    final.push([null, null, finalMatch]);
                }

                // Add in 3rd place match
                const thirdPlaceMatch = this.state.tournament.matchesL[0];
                if (thirdPlaceMatch.winner) {
                    if (thirdPlaceMatch.winner.id === thirdPlaceMatch.team1.id) {
                        final.push([1, 0, thirdPlaceMatch]);
                    } else {
                        final.push([0, 1, thirdPlaceMatch]);
                    }
                } else {
                    final.push([null, null, thirdPlaceMatch]);
                }

                const results = [...rounds, final];
                console.log({results});
                const singleElimination = {
                    teams: teams,
                    results: results
                };

                $(
                    $(this.brackets).bracket({
                        teamWidth: 100,
                        centerConnectors: true,
                        onMatchClick: this.handleMatchClick,
                        init: singleElimination
                    })
                );
            } else if (this.state.tournament.bracketType === "DOUBLE_ELIMINATION") {

                const winnersBracket = [];
                const losersBracket = [];
                const finalBracket = [];

                let counter = 0;
                let roundSwitch = this.state.tournament.maxTeams / 2;
                let round = [];
                this.state.tournament.matches.forEach(match => {
                    counter = this.assignRound(match, round, counter);
                    if (counter === roundSwitch) {
                        roundSwitch /= 2;
                        counter = 0;
                        winnersBracket.push(round);
                        round = [];
                    }
                });
                counter = 0;
                roundSwitch = this.state.tournament.maxTeams / 4;
                round = [];
                let mixAndMatchRound = false;
                this.state.tournament.matchesL.forEach((match) => {
                    if (counter === roundSwitch) {
                        if (mixAndMatchRound) {
                            roundSwitch /= 2;
                            mixAndMatchRound = false;
                        } else {
                            mixAndMatchRound = true;
                        }
                        counter = 0;
                        losersBracket.push(round);
                        round = [];
                    }
                    counter = this.assignRound(match, round, counter);
                });

                // Final match is stored as last match in lower bracket
                const match = this.state.tournament.matchesL[this.state.tournament.matchesL.length - 1];
                if (match.winner) {
                    if (match.winner.id === match.team1.id) {
                        finalBracket.push([1, 0, match]);
                    } else {
                        finalBracket.push([0, 1, match]);
                    }
                } else {
                    finalBracket.push([null, null, match]);
                }

                const results = [winnersBracket, losersBracket, [finalBracket]];

                console.log({results});
                const doubleElimination = {
                    teams: teams,
                    results: results
                };

                $(
                    $(this.brackets).bracket({
                        teamWidth: 100,
                        centerConnectors: true,
                        skipConsolationRound: true,
                        onMatchClick: this.handleMatchClick,
                        init: doubleElimination
                    })
                );
            }
        }

        return (
            <div style={{marginTop: "1rem", textAlign: "center"}}>
                <GridContainer justify="center">
                    <GridItem xs={12} md={8} lg={8} xl={8}>
                        <h1>{this.state.title}</h1>
                    </GridItem>
                    <GridItem xs={12} md={8} lg={8} xl={8}
                              style={{borderStyle: "solid", borderWidth: "0px 5px 0px 5px", borderColor: "#7d797a"}}>
                        <Pills
                            prize={this.state.prize}
                            tokenName={this.state.tokenName}
                            participants={this.state.participants.length}
                            tourType={this.state.tournament.tournamentType}
                            bracketType={this.state.tournament.bracketType}
                            teamSize={this.state.tournament.teamSize}
                            deadline={this.state.deadline}
                            handleModalClickOpen={this.handleModalClickOpen}
                        />
                    </GridItem>
                    <GridItem xs={12} md={8} lg={8} xl={8}
                              style={{borderStyle: "solid", borderWidth: "0px 5px 0px 5px", borderColor: "#7d797a"}}>
                        <Card
                            plain={true}
                            style={{marginTop: "0", position: "relative"}}
                        >
                            <CardMedia
                                image={this.state.coverImage}
                                style={{
                                    borderRadius: "0.5rem",
                                    paddingTop: "25%",
                                    minHeight: "10rem"
                                }}
                            />
                            <div
                                style={{
                                    position: "absolute",
                                    top: "20px",
                                    left: "15px",
                                    color: "white",
                                    backgroundColor: "transparent",
                                    fontSize: "x-large"
                                }}
                            >
                                {this.state.owner.id === localStorage.getItem("userId") ? (
                                    <div>
                                        <input
                                            accept="image/*"
                                            className={classes.input}
                                            style={{display: "none"}}
                                            id="raised-button-file"
                                            multiple
                                            onChange={this.handleFiles}
                                            type="file"
                                        />
                                        <input
                                            accept="image/*"
                                            className={classes.input}
                                            style={{display: "none"}}
                                            id="raised-button-file-tn"
                                            multiple
                                            onChange={this.handleFilesThumbnail}
                                            type="file"
                                        />
                                        <label htmlFor="raised-button-file">
                                            <Button
                                                variant="raised"
                                                component="span"
                                                className={classes.button}
                                            >
                                                Change Cover
                                            </Button>
                                        </label>
                                        <label htmlFor="raised-button-file-tn">
                                            <Button
                                                variant="raised"
                                                component="span"
                                                className={classes.button}
                                            >
                                                Change Thumbnail
                                            </Button>
                                        </label>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} md={8} lg={8} xl={8}
                              style={{borderStyle: "solid", borderWidth: "0px 5px 0px 5px", borderColor: "#7d797a"}}>
                        <Button
                            style={{backgroundColor: "#2a2a7d", borderRadius: "0.5rem"}}
                            onClick={() => this.state.isUserSignedIn ? this.handleModalClickOpen("participateModal") : this.props.history.push("/signUp")}
                            disabled={
                                this.state.participants.filter(x => x.owner.id === this.state.user.id).length > 0
                                || this.state.tournament.tournamentStatus !== "NEW"
                            }
                        >
                            Join Tournament
                        </Button>
                        {this.state.tournamentType === "PRIZE_POOL" ?
                            <Button
                                style={{backgroundColor: "#2a2a7d", borderRadius: "0.5rem"}}
                                onClick={() => this.state.isUserSignedIn ? this.handleModalClickOpen("contributeModal") : this.props.history.push("/signUp")}
                            >
                                Contribute To Prize Pool
                            </Button>
                            : ""}
                        {this.state.tournament.owner && this.state.tournament.owner.id !== localStorage.getItem("userId") ?
                            <div/>
                            :
                            <span>
                                <Button
                                    onClick={() => this.handleModalClickOpen("whitelistContributorModal")}
                                    style={{backgroundColor: "#2a2a7d", borderRadius: "0.5rem"}}
                                >
                                    White List Contributor
                                </Button>

                                {this.state.tournament.tournamentStatus === "NEW" ?
                                    <Button
                                        onClick={this.handleStartTournament}
                                        style={{backgroundColor: "#2a2a7d", borderRadius: "0.5rem"}}
                                    >
                                        Start Tournament!
                                    </Button>
                                    :
                                    <Button onClick={this.handlePaymentBR}
                                            style={{backgroundColor: "#2a2a7d", borderRadius: "0.5rem"}}
                                            disabled={this.state.tournament.tournamentStatus !== "FINISHED"}>
                                        $ Issue Payments $
                                    </Button>
                                }
                            </span>
                        }
                    </GridItem>
                    <GridItem xs={12} md={8} lg={8} xl={8}
                              style={{
                                  textAlign: "left",
                                  paddingTop: "3rem",
                                  borderStyle: "solid",
                                  borderWidth: "0px 5px 0px 5px",
                                  borderColor: "#7d797a",
                                  paddingRight: "5rem",
                                  paddingLeft: "5rem"
                              }}>
                        <Card>
                            <CardBody>
                                {this.state.tournament ?
                                    <ReactMarkdown
                                        source={this.state.tournament.description}
                                        escapeHtml={true}
                                    />
                                    : ""}
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} md={8} lg={8} xl={8}
                              style={{
                                  borderStyle: "solid",
                                  borderWidth: "0px 5px 0px 5px",
                                  borderColor: "#7d797a",
                                  paddingRight: "5rem",
                                  paddingLeft: "5rem",
                                  overflow: "hidden"
                              }}>
                        {this.state.tournament && (this.state.tournament.bracketType === "DOUBLE_ELIMINATION"
                            || this.state.tournament.bracketType === "SINGLE_ELIMINATION") ? (
                            <Card>
                                <div ref={node => this.brackets = node} className="App"/>
                            </Card>
                        ) : null}
                    </GridItem>
                    <GridItem xs={12} md={8} lg={8} xl={8}
                              style={{borderStyle: "solid", borderWidth: "0px 5px 0px 5px", borderColor: "#7d797a"}}>
                        {this.state.tournament && this.state.tournament.bracketType === "BATTLE_ROYALE" &&
                        this.state.tournament.tournamentStatus !== "NEW" ?
                            <BattleRoyale handlePointUpdate={this.handlePointUpdate}
                                          maxPlayers={this.state.tournament.maxPlayers}
                                          organizer={this.state.tournament.owner.id === localStorage.getItem("userId")}
                                          rounds={this.state.tournament.rounds}
                                          participants={this.state.tournament.participants}
                                          endRound={this.endRound}
                                          pointsToWin={this.state.tournament.pointsToWin}
                                          live={this.state.tournament.tournamentStatus === "LIVE"}
                            /> : null}
                    </GridItem>
                </GridContainer>
                <PrizesModal
                    openState={this.state.prizesModal}
                    closeModal={this.handleModalClose}
                    tournament={this.state.tournament}
                />
                <ContestantsModal
                    openState={this.state.contestantsModal}
                    closeModal={this.handleModalClose}
                    participants={this.state.participants}
                    maxTeams={this.state.tournament.maxTeams}
                    isOwner={this.state.owner.id === localStorage.getItem("userId")}
                    removeParticipant={this.removeParticipant}
                    history={this.props.history}
                />
                <ContributeModal
                    openState={this.state.contributeModal}
                    closeModal={this.handleModalClose}
                    tokenName={this.state.tokenName}
                    handleSimple={this.handleSimple}
                    handleFunding={this.handleFunding}
                />
                <ParticipateModal
                    openState={this.state.participateModal}
                    history={this.props.history}
                    closeModal={this.handleModalClose}
                    teamSize={this.state.tournament.teamSize}
                    handleParticipation={this.handleParticipation}
                />
                <SelectWinnerModal
                    openState={this.state.selectWinnerModal}
                    closeModal={this.handleModalClose}
                    match={this.state.match}
                    handleWinner={this.handleWinner}
                />
                <WhitelistContributorModal
                    openState={this.state.whitelistContributorModal}
                    closeModal={this.handleModalClose}
                    handleSimple={this.handleSimple}
                    handleContributorWhitelist={this.handleContributorWhitelist}
                />
            </div>
        );
    }

    assignRound(match, round, counter) {
        if (match.winner) {
            if (match.winner.id === match.team1.id) {
                round.push([1, 0, match]);
            } else {
                round.push([0, 1, match]);
            }
        } else {
            round.push([null, null, match]);
        }
        counter += 1;
        return counter;
    }
}

export default withStyles(componentsStyle)(Tournament);
