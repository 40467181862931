import {withStyles, Typography, CircularProgress} from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import React from "react";
import componentsStyle from "assets/jss/material-kit-react/views/components.jsx";
import assist from "bnc-assist";
import {bn_id} from "constants.js";
import Web3 from "web3";
import {apolloClient, isUserSignedIn} from "utils";
import gql from "graphql-tag";
import CardBody from "../../components/Card/CardBody";
import Button from "../../components/CustomButtons/Button";
import Container from "@material-ui/core/Container";
import Portis from "@portis/web3";
import TextField from "@material-ui/core/TextField";

const SIGNUP_USER = gql`
    mutation signUpUser($metadata: NewUserInput!) {
        signUpUser(metadata: $metadata) {
            id
            publicAddress
            username
            name
            walletType
            email
        }
    }`;

class SignUpForm extends React.Component {
    state = {
        user: null,
        web3: null,
        name: "",
        email: "",
        publicAddress: "",
        assistInstance: null,
        idChoice: null,
        walletReady: false,
        success: false,
        failure: false,
        portis: new Portis('f3b1dfa9-feee-44c9-8e41-3deca837c2e8', 'mainnet', {scope: ['email']})
    };

    handleSimple = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    componentDidMount = async () => {
        if (await isUserSignedIn() === true) {
            this.props.history.push("/");
        }
    };

    handleSubmit = event => {
        event.preventDefault();
        apolloClient
            .mutate({
                variables: {
                    metadata: {
                        publicAddress: this.state.publicAddress,
                        username: this.state.username,
                        name: this.state.name,
                        email: this.state.email,
                        walletType: this.state.idChoice,
                        password: this.state.password
                    }
                },
                mutation: SIGNUP_USER
            }).then(() => {
            this.setState({success: true});
        }).catch(() => {
            this.setState({failure: true});
        });
    };

    render() {
        if (this.state.success) {
            return <GridContainer justify="center" style={{minHeight: "70rem"}}>
                <GridItem xs={6}>
                    <Card>
                        <CardBody>
                            <h3 style={{color: "green"}}>User successfully created, please sign in!</h3>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        }

        if (this.state.failure) {
            return <GridContainer justify="center" style={{minHeight: "70rem"}}>
                <GridItem xs={6}>
                    <Card>
                        <CardBody>
                            <h3 style={{color: "red"}}>Error creating user!</h3>
                            <Button color="danger" onClick={() => {
                                this.setState({failure: false})
                            }}>Try Again</Button>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        }

        if (this.state.idChoice === null) {
            return <GridContainer justify="center" style={{minHeight: "70rem"}}>
                <GridItem xs={6}>
                    <Card plain={true}>
                        <GridContainer justify="center" style={{textAlign: "center"}}>
                            <GridItem xs={12} md={12}>
                                <h1>Sign Up With:</h1>
                            </GridItem>
                            <GridItem xs={12} md={6} lg={3}>
                                <Card plain={true}>
                                    <Container onClick={() => {
                                        this.setState({idChoice: "web3"});
                                        this.setState({assistInstance: null});
                                        this.setState({publicAddress: ""});
                                    }}
                                               style={{
                                                   cursor: "pointer",
                                                   textAlign: "center",
                                                   backgroundColor: "#2e2b2d",
                                                   color: "white"
                                               }}>
                                        <img src={require("assets/img/mm.svg")} alt={"Metamask"}
                                             style={{height: "6rem"}}/>
                                        <Typography variant="subtitle1" noWrap style={{marginTop: "0.5rem"}}>Metamask Wallet</Typography>
                                    </Container>
                                    <h5>Follow this <a style={{color: "red"}}
                                                       href="https://youtube.com/watch?v=ZIGUC9JAAw8"
                                                       target="_blank" rel="noopener noreferrer">guide</a> to
                                        learn how to create a Metamask wallet</h5>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} md={6} lg={3}>
                                <Card plain={true}>
                                    <Container onClick={() => {
                                        this.setState({idChoice: "portis"});
                                        this.setState({assistInstance: null});
                                        this.setState({publicAddress: ""});
                                    }}
                                               style={{
                                                   cursor: "pointer",
                                                   textAlign: "center",
                                                   backgroundColor: "#2e2b2d",
                                                   color: "white"
                                               }}>
                                        <img src={require("assets/img/portis-small.svg")} alt={"PORTIS"}
                                             style={{height: "6rem"}}/>
                                        <Typography variant="subtitle1" noWrap style={{marginTop: "0.5rem"}}>Portis
                                            Wallet</Typography>
                                    </Container>
                                    <h5>Beginner friendly blockchain wallet. <a style={{color: "red"}}
                                                                                href="https://www.portis.io/"
                                                                                target="_blank"
                                                                                rel="noopener noreferrer">More
                                        information</a></h5>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    </Card>
                </GridItem>
            </GridContainer>
        } else {
            if (this.state.idChoice === "web3") {
                if (window.web3) {
                    this.setState({idChoice: "WEB3_BROWSER"});
                    this.setState({web3: new Web3(window.web3.currentProvider)}, () => {
                        let bncAssistConfig = {
                            dappId: bn_id,
                            web3: this.state.web3,
                            networkId: 1
                        };
                        this.setState({assistInstance: assist.init(bncAssistConfig)}, () => {
                            this.state.assistInstance.onboard().then(() => {
                                this.state.assistInstance.getState().then(state => {
                                    this.setState({publicAddress: state.accountAddress});
                                    this.setState({walletReady: true});
                                });
                            });
                        });
                    });
                } else {
                    window.alert("You do not have a web3 enabled browser");
                    this.setState({idChoice: null});
                }
            } else if (this.state.idChoice === "portis") {
                this.setState({idChoice: "PORTIS"});
                this.state.portis.onLogin((publicAddress, email) => {
                    this.setState({publicAddress});
                    this.setState({email});
                    this.setState({walletReady: true});
                });
                let bncAssistConfig = {
                    dappId: bn_id,
                    web3: new Web3(this.state.portis.provider),
                    networkId: 1
                };
                this.setState({assistInstance: assist.init(bncAssistConfig)}, () => {
                    this.state.assistInstance.onboard().then(() => {
                    });
                });
            }

            return <GridContainer justify="center" style={{textAlign: "center", minHeight: "70rem"}}>
                <GridItem xs={12} md={6} lg={4} xl={4}>
                    <Card>
                        <CardHeader>
                            <h2>Sign Up</h2>
                        </CardHeader>
                        {this.state.walletReady ?
                            <div>
                                <form onSubmit={this.handleSubmit}>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            <TextField
                                                id="outlined-basic"
                                                label="Username"
                                                name="username"
                                                autoFocus={true}
                                                margin="normal"
                                                variant="outlined"
                                                fullWidth={false}
                                                required={true}
                                                onChange={this.handleSimple}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            <TextField
                                                id="outlined-basic"
                                                label="Name"
                                                name="name"
                                                margin="normal"
                                                variant="outlined"
                                                fullWidth={false}
                                                required={true}
                                                onChange={this.handleSimple}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            <TextField
                                                id="outlined-basic"
                                                label="Email"
                                                name="email"
                                                margin="normal"
                                                variant="outlined"
                                                value={this.state.email}
                                                fullWidth={false}
                                                required={true}
                                                onChange={this.handleSimple}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            <TextField
                                                id="outlined-basic"
                                                label="Password"
                                                name="password"
                                                margin="normal"
                                                variant="outlined"
                                                type="password"
                                                fullWidth={false}
                                                required={true}
                                                onChange={this.handleSimple}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer justify="center" style={{textAlign: "center"}}>
                                        <GridItem xs={12} style={{marginTop: "3rem"}}>
                                            {this.state.walletReady ?
                                                <div style={{color: "green"}}><i className="fas fa-lock"/> <Typography
                                                    variant="overline"
                                                    style={{marginLeft: "1rem"}}>{" " + this.state.publicAddress}</Typography>
                                                    <Typography variant="subtitle1">Wallet successfully
                                                        connected</Typography>
                                                </div>
                                                : <div><CircularProgress size={20}
                                                                         color="secondary"></CircularProgress>
                                                    <Typography
                                                        variant="overline" style={{marginLeft: "1rem"}}>Pending wallet
                                                        connection</Typography></div>
                                            }
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer justify="center">
                                        <GridItem xs={6} md={2}>
                                            <Button
                                                color="warning"
                                                size="lg"
                                                block
                                                style={{marginTop: "5rem"}}
                                                onClick={() => {
                                                    window.location.reload()
                                                }}
                                            >
                                                Back
                                            </Button>
                                        </GridItem>
                                        <GridItem xs={6} md={2}>
                                            <Button
                                                type="primary"
                                                color="success"
                                                htmltype="submit"
                                                disabled={!this.state.walletReady}
                                                size="lg"
                                                block
                                                style={{marginTop: "5rem"}}
                                            >
                                                Submit
                                            </Button>
                                        </GridItem>
                                    </GridContainer>
                                </form>
                            </div>
                            :
                            <GridContainer justify="center" style={{textAlign: "center"}}>
                                <GridItem xs={12} style={{marginTop: "3rem"}}>
                                    <div><CircularProgress size={20}
                                                           color="secondary"></CircularProgress><Typography
                                        variant="overline" style={{marginLeft: "1rem"}}>Pending wallet
                                        connection</Typography></div>
                                    <br/>
                                    {this.state.idChoice === "PORTIS" ?
                                        <Typography variant="body2">To get started, please create a Portis account or
                                            login to your existing one by following along in the popup to the right.
                                            Once you have your Portis account wallet connected, you'll be redirected to
                                            create your Daory account. <br/>(Make sure you have ad block disabled to
                                            continue)</Typography>
                                        : ""}
                                    <Button
                                        fullWidth={false}
                                        color="warning"
                                        size="lg"
                                        style={{marginTop: "5rem"}}
                                        onClick={() => {
                                            window.location.reload()
                                        }}
                                    >
                                        Back
                                    </Button>
                                    <br/>
                                    <br/>
                                </GridItem>
                            </GridContainer>}
                    </Card>
                </GridItem>
            </GridContainer>
        }
    }
}

export default withStyles(componentsStyle)(SignUpForm);
