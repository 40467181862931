import React from "react";
import {withStyles, Slide, Dialog, DialogContent} from "@material-ui/core";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "../Grid/GridItem";
import Button from "../CustomButtons/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

function Transition(props) {
    return <Slide direction="down" {...props} />;
}

function SignInModal({...props}) {
    const {classes, openState, closeModal, handleSimple, signIn} = props;

    return (
        <Dialog
            classes={{
                root: classes.center,
                paper: classes.modal
            }}
            open={openState}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => closeModal("signInModal")}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
        >
            <DialogContent id="modal-slide-description" className={classes.modalBody}>
                <GridContainer justify="center">
                    <GridItem>
                        <form onSubmit={signIn}>
                            <GridContainer justify="center">
                                <TextField
                                    id="outlined-basic"
                                    label="Username"
                                    name="username"
                                    autoFocus={true}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth={false}
                                    required={true}
                                    onChange={handleSimple}
                                />
                            </GridContainer>

                            <GridContainer justify="center">
                                <TextField
                                    id="outlined-basic"
                                    label="Password"
                                    name="password"
                                    margin="normal"
                                    variant="outlined"
                                    type="password"
                                    fullWidth={false}
                                    required={true}
                                    onChange={handleSimple}
                                />
                            </GridContainer>

                            <GridContainer justify="center">
                                <Button color="success" type="primary" htmltype="submit"
                                        style={{marginTop: "2rem"}}>Sign In</Button>
                            </GridContainer>
                        </form>
                    </GridItem>

                    <GridItem style={{textAlign: "left"}}>
                        <a href="/forgotPassword"><Typography>Forgot Password?</Typography></a>
                    </GridItem>
                </GridContainer>
            </DialogContent>
        </Dialog>
    );
}

export default withStyles(modalStyle)(SignInModal);
