import Fab from "@material-ui/core/Fab";
import WalletIcon from "@material-ui/icons/AccountBalanceWallet";
import React from "react";

export const WalletFAB = () => {
    if (localStorage.getItem("walletType") === "PORTIS") return <Fab color="primary" style={{
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
    }} aria-label="add" variant="extended" onClick={() => window.open('https://wallet.portis.io', '_blank', "location=yes,height=850,width=450,scrollbars=yes,status=yes")}>
        <WalletIcon/>
        Portis Wallet
    </Fab>;
    else return <div/>
};
